#art-selector-panel {
  height: 100px;
  background: lightgrey;
  //   position: fixed;
  //   bottom: 0;
  //   left: 50%;
  //   margin: auto;
  //   transform: translateX(-50%);
  .canvas-thumbnail {
    width: 100px;
    margin: 0 5px;
  }
}
